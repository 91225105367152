import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './createContest.css';
import { useNavigate } from 'react-router-dom';

function CreateContest() {
  const nav = useNavigate();
  const formData = new FormData();
  const [dueDate, setDueDate] = useState(new Date());

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formData.set(name, value);
  };

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0]; // Get the selected file
    // You can handle the file upload logic here, e.g., send it to a server
    console.log(imageFile)
    formData.append('imageFile', imageFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      formData.append('dueDate', dueDate);
      
      await fetch(`${process.env.REACT_APP_API_URL || ""}/contest/`, {
        method: 'POST',
        body: formData,
      })

      nav('/home')
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="create-contest">
      <h2>Create Contest</h2>
      <form onSubmit={handleSubmit}>
      <div className="createContestFormGroup">
          <label className="title">Title:</label>
          <textarea
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            placeholder="Enter contest title"
            required
          ></textarea>
        </div>
        <div className="createContestFormGroup">
          <label className="description">Description:</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Enter contest description"
            required
          ></textarea>
        </div>
        <div className="createContestFormGroup">
          <label className="author">Author:</label>
          <textarea
            id="author"
            name="author"
            value={formData.author}
            onChange={handleInputChange}
            placeholder="Enter Author's Name"
            required
          ></textarea>
        </div>
        <div className="createContestFormGroup">
          <label className="award">Award:</label>
          <textarea
            id="award"
            name="award"
            value={formData.award}
            onChange={handleInputChange}
            placeholder="Enter prize money"
            required
          ></textarea>
        </div>
        <div className='createContestFormGroup'>
          <label htmlFor="dueDate">Due Date:</label>
          <DatePicker 
                selected={dueDate} 
                onChange={(date) => setDueDate(date)} 
                dateFormat="yyyy/MM/dd"
                className="custom-date-picker"
          />
        </div>
        <div className="createContestFormGroup">
          <label htmlFor="contact">Contact:</label>
          <textarea
            id="contact"
            name="contact"
            value={formData.contact}
            onChange={handleInputChange}
            placeholder="Enter author contact info"
            required
          ></textarea>
        </div>
        <div className="createContestFormGroup">
          <label htmlFor="image">Upload Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </div>
        <button type="submit">Create Contest</button>
      </form>
    </div>
  );
}

export default CreateContest;
