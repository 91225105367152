import React from 'react';
import './ContestDetails.css'; // Make sure to create a corresponding CSS file for styling
import { useAuth } from '../AuthContext'; // Import your auth context
import { Link } from 'react-router-dom';

const parseText = (text) => {
  const lines = text.split('\n').filter(line => line.trim() !== '');
  const elements = [];
  
  let isInList = false;
  let listType = null;
  let listItems = [];

  lines.forEach((line, index) => {
    const trimmedLine = line.trim();
   
    if (trimmedLine.startsWith('*')) {
      if (isInList && listType !== 'ul') {
        elements.push(<ol key={`ol-${index - 1}`}>{listItems}</ol>);
        listItems = [];
      }
      isInList = true;
      listType = 'ul';
      listItems.push(<li key={index}>{trimmedLine.substring(1).trim()}</li>);
    } else {
      if (isInList) {
        elements.push(listType === 'ol' ? <ol key={`ol-${index - 1}`}>{listItems}</ol> : <ul key={`ul-${index - 1}`}>{listItems}</ul>);
        isInList = false;
        listItems = [];
      }
      if (trimmedLine.endsWith(':')) {
        elements.push(<p key={index}><strong>{trimmedLine}</strong></p>);
      } else {
        elements.push(<p key={index}>{trimmedLine}</p>);
      }
    }
  });

  if (isInList) {
    elements.push(listType === 'ol' ? <ol key="last">{listItems}</ol> : <ul key="last">{listItems}</ul>);
  }

  return elements;
};


function ContestDetails({ contest }) {

  const { state } = useAuth()
  const isAuthenticated = state.isAuth
  const handleCreateSubmission = () => {

  }

  return (
    <div className="contest-details-container">
      <div className="contest-image-container">
        <img src={contest.imageUrl} alt={contest.title} className="contest-image" />
      </div>
      <h1 className="contest-title">{contest.title}</h1>
      <div className="contest-info-container">
        <div className="contest-info">
          <p className="contest-description">{parseText(contest.description)}</p>
        </div>
        <div className="contest-meta">
          <div className="contest-host"><strong>Hosted by:</strong> {contest.author}</div>
          <div className="contest-prizes"><strong>Prizes:</strong> {contest.award}</div>
          {/* <div className="contest-participation"><strong>Participants:</strong> {contest.submissions}</div> */}
          <div className='contest-duedate'><strong>Due Date:</strong> {contest.dueDate} </div>
          {/* <div className="contest-tags">
            <strong>Tags:</strong>
            {contest.tags?.map((tag, index) => <span key={index} className="tag">{tag}</span>)}
          </div> */}
        </div>
      </div>
      <Link to={isAuthenticated ? 'https://forms.gle/EDJG2c3WwxWPFVFm8' : '/login'} state={{ from: '/contests/'+encodeURIComponent(contest.id) }}> 
      <button className="createSubmission" onClick={handleCreateSubmission}>Submit Video</button>
      </Link>
    </div>
  );
};

export default ContestDetails;
