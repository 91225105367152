import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './signup.css';

function SignUpPage() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const nav = useNavigate();
  const location = useLocation();

  var from = location.state?.from?.pathname || "/";
  if (from === "/register") {
    from = "/";
  }

  const handleSignUp = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Perform your user registration logic here (e.g., API call, validation)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL || ""}/auth/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, email, phoneNumber, password }),
      });

      // Handle the response from the backend
      if (response.ok) {
        nav('/confirmUser', {state: { email }}); // Redirect to the home page after registration
      } else {
        // If the response status is not OK, parse the JSON for error details
        const errorData = await response.json();
        console.error('Error signing up:', errorData);
        console.log("Sign up failed")
        // You can access specific error properties, for example:
        const errorMessage = errorData.message;
        alert('Error message:' + errorMessage);
      }
    } catch (error) {
      // Handle any network or server errors
      console.log(error)
    }

  };

  return (
    <div className="signup-page">
      <form className="signup-Form" onSubmit={handleSignUp}>
        <div className="name-group">
            <div className="name-input">
              <input
                className='firstname-input'
                type="text"
                id="firstName"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="name-input">
              <input
                className='lastname-input'
                type="text"
                id="lastName"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
        </div>
        <div className="signup-form-group">
          <input
            type="email"
            id="email"
            className='email-password-input'
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {/* <div className="signup-form-group">
          <input
            type="phone_number"
            id="phone_number"
            className='email-password-input'
            placeholder="Phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div> */}
        <div className="signup-form-group">
          <input
            type="password"
            id="password"
            className='email-password-input'
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button className='signUpButton' type="submit">Sign Up</button>
      </form>
      <Link to="/login"> Already have an existing account? Log in here </Link>
    </div>
  );
}

export default SignUpPage;
