// src/components/NavBar.js
import React from 'react';
import './navbar.css';
import { useAuth } from '../AuthContext';
import image from '../assets/logo.png'

function NavBar({ isAuthenticated }) {
  const { state } = useAuth()
  isAuthenticated = state.isAuth
  return (
    <nav className="custom-navbar">
      <div className="navbar-brand">
        <a href='/' className='nav-link'>
          <img src={image} alt="Navbar Logo" className="navbar-image" />
        </a>
      </div>
      <ul className="navbar-links">

        {isAuthenticated ? (
          <>
            <li className='nav-item'>
              Hello {state.user.firstName}
            </li>
            {/* <li className="nav-item">
              <a href="/submissions" className="nav-link">
                Submissions
              </a>
            </li> */}
            <li className="nav-item">
              <a href="/profile" className="nav-link">
                Profile
              </a>
            </li>
          </>
        ) : (
          <>
            <li className="nav-item">
              <a href="/login" className="nav-link">
                Log In
              </a>
            </li>
            <li className="nav-item">
              <a href="/register" className="nav-link">
                Register
              </a>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

export default NavBar;
