import React from 'react';
import './TermsPrivacy.css'; // Shared CSS file

function Privacy() {
    return (
        <div className="terms-privacy-content">
            <h1>Privacy Policy for Vidtie</h1>
            <h2>Introduction</h2>
            <p>
                Your privacy is important to us. This policy explains how we collect, use, and protect your information.
            </p>
            <h2>Data Collection</h2>
            <p>
                We collect information you provide during account creation, namely your name and email address.
            </p>
            <h2>Use of Personal Data</h2>
            <p>
                We use your personal data to personalize content on the app and improve our services.
            </p>
            <h2>Data Sharing</h2>
            <p>
                Vidtie does not share your personal data with third parties.
            </p>
            <h2>User Rights</h2>
            <p>
                <strong>Data Deletion:</strong> Users can request the deletion of their account and associated data by emailing admin@vidtie.com.
            </p>
            <h2>Data Security</h2>
            <p>
                We take reasonable steps to protect your information from unauthorized access, alteration, or destruction.
            </p>
            <h2>Changes to the Policy</h2>
            <p>
                This Privacy Policy may be updated from time to time. Users are advised to review it periodically.
            </p>
        </div>
    );
}

export default Privacy;