import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import './confirmUser.css';

const UserNotConfirmedPage = () => {
  const [confirmationCode, setconfirmationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const nav = useNavigate();
  const location = useLocation();
  const { dispatch } = useAuth();

  const handleVerification = async () => {
    try {
      // Confirm the user with the entered verification code
      const username = location.state && location.state.email;
      const response = await fetch(`${process.env.REACT_APP_API_URL || ""}/auth/confirm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ confirmationCode, username }),
      });

      // Handle the response from the backend
      if (response.ok) {
        const userData = await response.json()
        const { id, email, firstName, lastName} = userData;
        const user = { id: id, email: email, firstName: firstName, lastName: lastName };
        console.log(user);
        dispatch({ type: 'LOGIN', payload: user });
        nav('/home'); // Redirect to the home page after registration
      } else {
        // Verification failed, display error message or perform appropriate actions
        alert("Wrong verification number")
      }
    } catch (error) {
      // Handle any network or server errors
      console.log(error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="confirmUser">
      <h2>Account Verification</h2>
      <p>Your account is not yet confirmed. Please check your email for the verification code.</p>
      <p>Please check your spam if you do not see the verification code in your inbox</p>

      <label className='confirmUserLabel'>Verification Code:</label>
      <input
        className='confirmationCodeInput'
        type="text"
        value={confirmationCode}
        onChange={(e) => setconfirmationCode(e.target.value)}
      />

      <button className="confirmUserButton" onClick={handleVerification}>Verify Account</button>

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
};

export default UserNotConfirmedPage;
