import React from 'react';
import './TermsPrivacy.css'; // Shared CSS file

function Terms() {
    return (
        <div className="terms-privacy-content">
            <h1>Terms of Service for Vidtie</h1>


            <h2>Introduction</h2>

            <p>
                Welcome to Vidtie! By using our services, you agree to these terms. Please read them carefully.
            </p>

            <h2>User Accounts</h2>

            <p>
                Users must provide their name and email address for account creation. We do not impose age or location restrictions, but we expect users to comply with their local laws.
            </p>

            <h2>Use of the Service</h2>
            <p>
                <div>
                    <strong>Prohibited Content:</strong> Users must not upload explicit, violent, or copyrighted content.
                </div>
                <div>
                    <strong>User-Generated Content:</strong> Users retain ownership of their videos. By submitting a video, users grant Vidtie the right to publish their videos on the platform, provided the user is credited.
                </div>
            </p>
            <h2>Contest Participation</h2>

            <p>
                Contest rules and the method of selecting winners may vary and will be specified for each contest. Vidtie reserves the right to change contest structures.
            </p>

            <h2>Liability</h2>
            <p>
                Vidtie is not liable for content posted by users. Users are responsible for the content they upload and the consequences of posting or publishing it.
            </p>
            <h2>General Provisions</h2>
            <p>
                Vidtie reserves the right to modify these Terms at any time. Users are encouraged to review these Terms periodically.

            </p>
        </div>
    );
}

export default Terms;