import React, { createContext, useContext, useReducer, useEffect } from 'react';

// Define the initial state for user authentication
const initialState = {
  user: null, // User data (null if not logged in)
  isAuth: false, // Authentication status
};

// Create the context
const AuthContext = createContext();

// Define actions for user authentication
const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';

// Reducer function to update state based on actions
function authReducer(state, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload,
        isAuth: true,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        isAuth: false,
      };
    default:
      return state;
  }
}

// Create a custom provider component to wrap your app
export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  // Check for an existing session on app load (optional)
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));

    if (storedUser) {
      dispatch({ type: LOGIN, payload: storedUser });
    }
  }, []);

  // Effect to store user data when the user logs in or out
  useEffect(() => {
    if (state.user) {
      // Store user data in localStorage
      localStorage.setItem('user', JSON.stringify(state.user));
    } else {
      // Remove user data from localStorage on logout
      localStorage.removeItem('user');
    }
  }, [state.user]);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook to access the authentication context
export function useAuth() {
  return useContext(AuthContext);
}

export default AuthContext;
