import React, { useEffect } from 'react';
import {Layout} from './components/layout';
import LoginPage from './pages/login';
import SignUpPage from './pages/signup';
import NewContestPage from './pages/newContest';
import CreateContestPage from './pages/createContest';
import ProfilePage from './pages/profile';
import WaitlistPage from './pages/waitlist';
import ConfirmUserPage from './pages/confirmUser';
import HomePage from './pages/homepage';
import { AuthProvider } from './AuthContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import BrowserRouter and Route
import './App.css';
import ContestPage from './pages/ContestPage';
import { useLocation } from 'react-router-dom';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import ReactGA from 'react-ga';
// Initialize React Ga with your tracking ID
ReactGA.initialize('G-27QH0DX9JF');

function App() {

  return (
    <Router>
      <AuthProvider>
      <div className="App">
        {/* Conditionally render the NavBar based on the route */}
        <Routes>
          <Route path='/waitlist' element={<WaitlistPage />} />
          <Route path='/home' element={<HomePage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/" element={<Layout/>}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<SignUpPage />} />
              <Route path="/confirmUser" element={<ConfirmUserPage />} />
              <Route path="/newContest" element={<NewContestPage />} />
              <Route path='/createContest' element={<CreateContestPage />} />
              <Route path='/profile' element={<ProfilePage />} />
              <Route path='/contests/:id' element={<ContestPage />} />
              <Route path='/terms' element={<Terms />} />
              <Route path='/privacy' element={<Privacy />} />
          </Route>
          {/* Your other components and content */}
        </Routes>
      </div>
      </AuthProvider>
    </Router>
  );
}

export default App;