import React, { useState, useEffect } from 'react';
import './profile.css';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const nav = useNavigate();
  const { state, dispatch } = useAuth();
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
  });

  useEffect(() => {
    // Update formData when state is loaded
    if (state.user) {
      setFormData({
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        email: state.user.email,
      });
    }
  }, [state.user]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = () => {
    // Save the updated user information (formData) to the server or update it in your state management.
    // Here, we're simulating saving by logging the data.
    console.log('Updated User Data:', formData);
    setEditMode(false);
  };

  const handleLogout = async () => {
    try {
      
      dispatch({ type: 'LOGOUT' });
      nav('/home'); // Redirect to the home page after registration
      
    } catch (error) {
      // Handle any network or server errors
      console.log(error)
    }
  };

  return (
    <div className="profile">
      <h2>Profile</h2>
      <div className="profile-info">
        <div className="profile-field">
          <label>First Name:</label>
          {editMode ? (
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          ) : (
            <span>{formData.firstName}</span>
          )}
        </div>
        <div className="profile-field">
          <label>Last Name:</label>
          {editMode ? (
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          ) : (
            <span>{formData.lastName}</span>
          )}
        </div>
        <div className="profile-field">
          <label>Email:</label>
          {editMode ? (
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          ) : (
            <span>{formData.email}</span>
          )}
        </div>
      </div>
      <div className="profile-actions">
        {editMode ? (
          <button onClick={handleSaveClick}>Save</button>
        ) : (
          <button onClick={handleEditClick}>Edit</button>
        )}

        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
}

export default Profile;
