import { init, Form } from '@feathery/react';
const SDK_Key = process.env.REACT_APP_FEATHERLY_SDK;

function WaitlistPage() {
  // Initialize Feathery
  console.log(SDK_Key)
  init(SDK_Key);
  // Show the `formName` Feathery form
  return <Form formName='VidTie' />
}

export default WaitlistPage