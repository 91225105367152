import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './login.css';
import { useAuth } from '../AuthContext';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const nav = useNavigate();
  const location = useLocation();
  const { dispatch } = useAuth();

  var from = location.state?.from || "/";
  console.log(location.state?.from, "frommm")
  if (from === "/register") {
    from = "/";
  }

  // Handle form submission
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Perform your authentication logic here (e.g., API call, validation)
    // For simplicity, we'll assume successful login and redirect to the home page
    // Perform your user registration logic here (e.g., API call, validation)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL || ""}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      // Handle the response from the backend
      if (response.status === 200) {
        const userData = await response.json()
        const { id, email, firstName, lastName} = userData;
        const user = { id: id, email: email, firstName: firstName, lastName: lastName };
      
        dispatch({ type: 'LOGIN', payload: user });
        nav(from, { replace: true }); // Redirect to the home page after registration
      } else {
        // Login failed, display error message or perform appropriate actions
        console("No account registered with username and password");
        alert("Create an account");
      }
    } catch (error) {
      // Handle any network or server errors
      console.log(error)
    }
  };

  return (
    <div className="login-page">
      <p>Welcome Back</p>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button className='loginButton' type="submit">Login</button>
      </form>
      <Link to="/register"> New User? Sign up here </Link>
    </div>
  );
}

export default LoginPage;
