// In a parent component or page
import React, { useState, useEffect } from 'react';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import ContestDetails from '../components/ContestDetails';
import { useAuth } from '../AuthContext'; // Import your auth context


function ContestPage() {
  const { id } = useParams();
  const { state } = useAuth()
  const isAuthenticated = state.isAuth
  const [contest, setContest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchContestDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL || ""}/contest/${encodeURIComponent(id)}`,
          {
            method: 'GET',
          });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setContest(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchContestDetails();
  }, [id]); // Dependency array: re-run the effect if the title changes

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    // isAuthenticated ? (
    <div>
      <ContestDetails contest={contest} />
    </div> 
    // ) :
    // <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ContestPage;
