import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './homepage.css';
import NavBar from "../components/navbar"
import Countdown from '../components/countdown';
import backgroundVideo from '../assets/video-home-1.mp4'; // Make sure to replace with the actual path to your video file
import noImage from '../assets/no-image.jpg';
import image1 from '../assets/image-4.png';
import image2 from '../assets/image-5.png';
import image3 from '../assets/image-6.png';
import image from '../assets/logo_overlay.png';
import Footer from '../components/footer';
import ReactGA from 'react-ga';

const SectionTitle = ({ title, viewAll }) => (
  <div className="section-title">
    <h2>{title}</h2>
    {viewAll && <a href="/home">{viewAll}</a>}
  </div>
);

const Card = ({ url, title, subtitle, image }) => (
  <Link to={url}>
    <div className="card">
      <img src={image} alt={title} className="card-image" />
      <div className="card-content">
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </div>
  </Link>
);

const CompetitionCard = ({ competition }) => (
  <Card
    url={`/contests/${encodeURIComponent(competition.id)}`}
    title={competition.title}
    subtitle={`${competition.award} - ${competition.author}`}
    image={competition.imageUrl || noImage}
  />
);

const SolutionCard = ({ solution }) => (
  <Card
    title={solution.title}
    subtitle={`${solution.upvotes} upvotes`}
    image={solution.imageUrl || noImage}
  />
);

function HomePage({ isAuthenticated }) {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);


  // Placeholder data
  var [competitions, setCompetitions] = useState([]);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();
  }, []);

  useEffect(() => {
    const fetchCompetitions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL || ""}/contest/competitions`, {
          method: 'GET',
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        var data = await response.json();
        if (windowSize.width < 768) {
          data = data.splice(0, 1);
        }
        setCompetitions(data);
      } catch (error) {
        console.error('Error fetching competitions:', error);
      }
    };

    fetchCompetitions();
  }, [windowSize]);

  const solutions = [
    { title: "Coming soon...", upvotes: "0" },
    // ...other solutions
  ];

  var latestCompetition = null
  if (competitions.length > 0) {
  latestCompetition = `/contests/${encodeURIComponent(competitions[0].id)}`
  console.log(competitions)
  }

  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = true;
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log("Video playing successfully");
          })
          .catch(error => {
            console.log("Autoplay was prevented. Attempting to play video on user interaction.");
            video.addEventListener('click', () => {
              video.play();
            });
          });
      }
    }
  }, []);

  return (
    <div className="home">
      <NavBar isAuthenticated={isAuthenticated} />
      <div>
      <header className="home-header">
        <video ref={videoRef} playsInline className="backgroundVideo" autoPlay loop muted>
          <source src="https://vidtie.s3.us-east-2.amazonaws.com/assets/video-home-1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay">
          <img src={image} alt="Logo" className="home-image" />
          <p className="subtitle">Showcase your creativity, compete with the best, and win amazing prizes!</p>
          <a href={latestCompetition}>
          <button className="enter-contest-button">Enter Latest Contest</button>
          </a>
          {/* <p className="powered-by">Powered by Vidtie</p> */}
          
          <Countdown></Countdown>
        </div>
      </header>

      <div className="main-app">
      <h2 className="user-types-title">Who's on Vidtie?</h2>
      <section className="user-types">
        <div className="user-type learner">
          <img src={image1} alt="Placeholder" className="user-type-photo" />
          <h3>New Editors</h3>
          <p>Embark on your video editing journey with our curated tutorials and beginner challenges.</p>
          {/* ... */}
        </div>
        <div className="user-type developer">
          <img src={image2} alt="Placeholder" className="user-type-photo" />
          <h3>Professional Editors</h3>
          <p>Refine your skills, collaborate on projects, and take part in advanced challenges.</p>
          {/* ... */}
        </div>
        <div className="user-type researcher">
          <img src={image3} alt="Placeholder" className="user-type-photo" />
          <h3>Filmmakers & Producers</h3>
          <p>Explore groundbreaking editing techniques and connect with top talent for your film projects.</p>
          {/* ... */}
        </div>
      </section>

        <SectionTitle title="Competitions" viewAll="View all" />
        <div className="card-container">
          {competitions.map(competition => (
            <CompetitionCard key={competition.id} competition={competition} />
          ))}
        </div>

        <SectionTitle title="Contest Winner Video Tutorial" viewAll="View all" />
        <div className="card-container">
          {solutions.map(solution => (
            <SolutionCard key={solution.title} solution={solution} />
          ))}
        </div>
      </div>
      <Footer />
      </div>
    </div>
  );
}

export default HomePage;
